import { useEffect, useState } from "react";
import Button from "../button";
import Card from "./Card";

const Main = () => {
  const [windowWidth, setWindowWidth] = useState(getWidth());

  function getWidth() {
    return window.innerWidth;
  }

  useEffect(() => {
    const setSize = () => setWindowWidth(getWidth());
    window.addEventListener("resize", setSize);

    return () => window.removeEventListener("resize", setSize);
  }, [windowWidth]);

  return (
    <div className="flex flex-col problematic-page scrollable-container md:mb-5 ">
      <div
        className="max-w-[660px]
       mb-[46px]
        md:mb-[30px]
        lg:mb-[36px]
        xl:mb-10"
      >
        <h1
          className="
        text-black
        text-[48px]
        md:text-[52px]
        lap:text-[64px]
        lg:text-[72px]
        xl:text-[80px]
        font-semibold
        uppercase
        leading-[124%]"
        >
          blue7 fund
        </h1>
        <h3
          className="text-[22px]
        lap:text-[26px]
        lg:text-[32px]
        xl:text-[40px]"
        >
          A fund for founders
        </h3>
      </div>
      <div
        className="max-w-[860px]
        h-[1px]
       bg-black
       mt-8
       md:mt-11
       lg:mt-[58px]
       xl:mt-[60px]
    
       mb-8"
      ></div>
      <div
        className="max-w-[860px]
       text-lg
       lap:text-xl
       xl:text-[22px]
       leading-[140%]
       space-y-4
       lap:gap-y-5
       xl:gap-y-6"
      >
        <p>
          Blue7 Fund was born of a desire to create the most robust alignment of
          peer exposure, founders investing in founders. Too often the
          innovators and drivers of the space are siloed from their counterparts
          in a landscape that traditionally prefers the interests of investors
          and capital providers. Blue7’s founders collective is an opportunity
          for the best founders in the space to find and align themselves with
          fellow builders, creating the frontier of Web3’s push forward.
        </p>
        <p>
          The fund’s objective is to foster a new era of hyper-aligned
          foundership through shared exposure, collaborative tools and
          meaningful partnerships in a drive for adoption.
        </p>
      </div>
      <div
        className="mt-[20px]
        mb-[30px]
      lap:mt-[28px]
      xl:mt-[34px]
      items-center
      gap-x-[10px]
      flex"
      >
        <span>
          <img
            className="h-[50px]
             w-[50px]"
            src="/assets/images/founders-collective/email-icon.svg"
            alt="email-icon"
          />
        </span>
        <span
          className="text-[20px]
          lap:text-[24px]
          xl:text-[28px]"
        >
          fund@blue7.io
        </span>
      </div>
      <div
        className="flex
        flex-col
        sm:hidden"
      >
        {/* <h2
          className="text-2xl
        font-semibold
        uppercase
        mb-8"
        >
          If you are interested in joining founder's collective, please get in
          touch.
        </h2> */}
        {/* <div
          className="flex
        flex-col
        gap-y-6"
        >
          {windowWidth > 375 && (
            <>
              <Button>Suguna</Button>
              <Button>Valerina</Button>
            </>
          )}
          <Button>fund@blue7.io</Button>
        </div> */}
      </div>
      <div className="hidden">
        <h3
          className="mb-8
          md:mb-9
          xl:mb-10
        text-[32px]
        font-semibold
        uppercase"
        >
          Partnerships
        </h3>
        <div
          className="mb-[60px]
        border-black
        grid
        mob:grid-cols-2
        lap:grid-cols-4"
        >
          <Card
            lastCol={windowWidth < 575}
            lastRow={windowWidth > 991}
            img="chingari"
          />
          <Card
            lastCol={windowWidth < 991}
            lastRow={windowWidth > 991}
            img="constellation"
          />
          <Card
            lastCol={windowWidth < 575}
            lastRow={windowWidth > 575}
            img="gelato"
          />
          <Card lastCol lastRow img="icon" />
        </div>
      </div>
    </div>
  );
};

export default Main;

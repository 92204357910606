import Navlink from "./Navlink";
import Button from "../button";
import { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { getAllAsyncData } from "../../Redux/actions/blogsAction";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const [isSidebar, setIsSidebar] = useState(false);
  const blogData = useSelector((state) => state.blogData);
  const slug = blogData?.blogs?.[0];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = {
    page: 1,
    limit: 10,
  };

  const handleNavigate = () => {
    navigate(`/${slug.slug}`, {
      state: {
        data: slug,
      },
    });
  };
  useEffect(() => {
    dispatch(getAllAsyncData(data));
  }, []);

  return (
    <>
      <div
        className="bg-[#f3f3f3]
        problematic-page
        scrollable-container
        py-6
        lg:py-[28px]
        xl:py-[34px]
        mb-14
        lg:mb-[58px]
        xl:mb-16
        flex
        justify-between
        items-center
        z-[1201]"
      >
        <div
          className="flex
          items-start
        z-[1201]"
        >
          <div>
            <Link to="/">
              <img
                className="max-w-[72px]
              max-h-[32px]"
                src="/assets/images/header/logo.png"
                alt="logo"
              />
            </Link>
          </div>
          <div
            className="xl:ml-[128px]
          ml-[60px]
          hidden
          lap:flex
          xl:gap-x-8
          gap-x-4"
          >
            <Navlink path="/">home</Navlink>
            <Navlink path="/public">public</Navlink>
            <Navlink path="/private">private</Navlink>
            {/* <Navlink path="/b7xlabs">B7XLabs</Navlink> */}
            {/* <Navlink path="/blog">Blog</Navlink> */}
            <Navlink path={slug?.slug} state={slug}>
              Blog
            </Navlink>
          </div>
        </div>
        <div
          className="hidden
          lap:flex"
        >
          <Button link="private">Private Portfolio</Button>
        </div>
        <div
          className="block
          z-[1301]
        lap:hidden"
          onClick={() => setIsSidebar(!isSidebar)}
        >
          <img
            src={`/assets/images/header/${
              isSidebar ? "menu-close.svg" : "menu.svg"
            }`}
            alt="menu-icon"
          />
        </div>
      </div>
      <Sidebar isSidebar={isSidebar} setIsSidebar={setIsSidebar} />
    </>
  );
};

export default Header;

import Left from "./Left";
import Right from "./Right";

const index = () => {
  return (
    <div
      className="flex
    justify-between
    scrollable-container
    problematic-page
    flex-col
    sm:flex-row"
    >
      <Left />
      <Right />
    </div>
  );
};

export default index;

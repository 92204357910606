import { useLocation } from "react-router-dom";

const Container = ({ children }) => {
  const { pathname } = useLocation();
  return (
    <div
      className={`${pathname === '/private' ? "h-screen" : "h-[100vh]"}
      relative
      overflow-y-auto
      scrollbar-hide
      mx-auto
      px-[24px]
      sm:px-[44px]
      md:px-[60px]
      lg:px-[100px]
      xl:px-[140px]`}
    >
      {children}
    </div>
  );
};

export default Container;

import { axiosClient } from "../../components/axios";

export const loading = () => {
  return {
    type: "IS_LOADING",
  };
};

export const AddB7xlabs = (data) => {
  return {
    type: "ADD_B7XLabs",
    payload: data,
  };
};

// export const addB7xlabsData = (data) => {
//   console.log(data);
//   return (dispatch) => {
//     dispatch(loading());
//     axiosClient
//       .post("/add-register", data)
//       .then((res) => {
//         dispatch(AddB7xlabs(res));
//       })
//       .catch((ere) => {
//         console.log(ere);
//       });
//   };
// };

export const addB7xlabsData = (formData) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const data = await axiosClient.post("/add-register", formData);

      return data;
    } catch (error) {
      console.log(error);
    }
  };
};

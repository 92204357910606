import { useEffect, useState } from "react";
import Pagination from "./Pagination";
import Card from "../founders-collective/Card";
import axios from "axios";
import { axiosClient } from "../axios";

const Main = () => {
  const [windowWidth, setWindowWidth] = useState(getWidth());
  const [pagination, setPagination] = useState(false);
  const [myData, setMyData] = useState([]);
  const [page, setPage] = useState(1);

  function getWidth() {
    return window.innerWidth;
  }
  console.log(myData, "this is a my data");
  useEffect(() => {
    const setSize = () => setWindowWidth(getWidth());
    window.addEventListener("resize", setSize);
    getWidth() < 575 && setPagination(true);
    return () => window.removeEventListener("resize", setSize);
  }, [windowWidth]);

  useEffect(() => {
    axiosClient.post("project-list").then((data) => {
      setMyData(data.data.data);
    });
  }, [page]);
  return (
    <>
      <div className="">
        <div className="max-w-[1280px]">
          <h1
            className="pb-10
          scrollable-container
          problematic-page
          md:pb-[48px]
          lg:pb-[52px]
          xl:pb-[60px]
        text-black
          text-[40px]
          md:text-[46px]
          lap:text-[48px]
          lg:text-[50px]
          xl:text-[52.2px]
          font-semibold
          uppercase
          leading-[124%]"
          >
            PORTFOLIO - MULTI STRATEGY
          </h1>
        </div>
        <div
          className="mb-10
        md:mb-[48px]
        lg:mb-[52px]
        xl:mb-[60px]
        grid
        mob:grid-cols-2
        md:grid-cols-4
        lg:grid-cols-6
        gap-[10px]"
        >
          {myData &&
            myData.map((data) => {
              return (
                <div
                  className="group relative scrollbar-hide overflow-hidden"
                  key={data.id}
                >
                  <Card
                    liquid_strategy={data.liquid_strategy}
                    header={data.name}
                    img={data.image}
                    content={data.description}
                    year={data.year}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Main;

const Card = ({ img, header, year, content, liquid_strategy }) => {
  console.log(liquid_strategy);
  return (
    <>
      <div className="border border-black rounded-[5px]">
        <div
          className="mx-auto
          h-[185px]
        w-[185px]
        flex
        justify-center
        items-center
        p-4 relative"
        >
          <img src={img} alt="logo-img" />
        </div>
      </div>
      <div
        className="p-3 rounded-[5px] absolute -bottom-[100%] group-hover:bottom-[1px] h-[186px] w-full bg-black overflow-hidden duration-500
       transition-all"
      >
        <div
          className="flex
         items-center
          justify-between
          text-white
         font-medium"
        >
          <h2
            className="text-lg
          capitalize
          w-[111px]
          truncate"
          >
            {header}
          </h2>
          <span>{year}</span>
        </div>
        {liquid_strategy === "1" && (
          <div
            className="flex
         items-center
          justify-between
          text-white
         font-medium"
          >
            <h2
              className="text-sm
          capitalize
          w-[120px]
          truncate"
            >
              liquid strategy
            </h2>
          </div>
        )}

        <p className="mt-4 scrollbar-hide text-white overflow-y-scroll h-[110px] leading-[140%] text-sm">
          {content}
        </p>
      </div>
    </>
  );
};

export default Card;

import React from "react";

const Left = () => {
  return (
    <div className="md:max-w-[350px] lg:max-w-[550px]">
      <h1
        className="mb-[26px]
        md:mb-[30px]
        lg:mb-[36px]
        xl:mb-10
        text-black
        text-[48px]
        md:text-[52px]
        lap:text-[64px]
        lg:text-[72px]
        xl:text-[80px]
      font-semibold
      leading-[124%]"
      >
        B7XLabs
      </h1>
      <h2
        className="mb-[26px]
        md:mb-[30px]
        lg:mb-[36px]
        xl:mb-10
        text-black
        text-[32px]
        md:text-[40px]
        xl:text-[45px]
      font-semibold
      leading-[124%]"
      >
        Early registration
      </h2>
      <p
        className="mb-[26px]
        md:mb-[30px]
        lg:mb-[36px]
        xl:mb-10
        text-black
        text-[25px]
        md:text-[30px]
        xl:text-[35px]
      font-semibold
      leading-[124%]"
      >
        Matching developers with interesting problems - be part of Web3’s
        frontier developments
      </p>
    </div>
  );
};

export default Left;

import React, { useState } from "react";

const StapOne = ({ onNext, setData, data }) => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    url: "",
    experience: "",
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic form validation
    const newErrors = {};

    if (!formData.fname.trim()) {
      newErrors.fname = "First Name is required";
    }

    if (!formData.lname.trim()) {
      newErrors.lname = "Last Name is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    }

    if (!formData.url.trim()) {
      newErrors.url = "Portfolio is required";
    }

    if (!formData.experience) {
      newErrors.experience = "Experience is required";
    }

    if (Object.keys(newErrors).length === 0) {
      // If no errors, proceed to the next step
      onNext();
      setData({ ...data, ...formData });
      console.log(formData, "THIS IS A formData");
    } else {
      // Update errors state to display validation messages
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  return (
    <>
      <form className="stapOne" onSubmit={handleSubmit}>
        <div className="mb-6 text-black font-bold capitalize text-[18px]">
          basic information
        </div>
        <div className="flex flex-col sm:flex-row md:flex-col lg:flex-row justify-between gap-5 mb-6 ">
          <div className="relative float-label-input w-full">
            <input
              type="text"
              id="fname"
              placeholder=" "
              className={`block w-full bg-white focus:outline-none focus:shadow-outline border border-black  py-2 px-3  appearance-none leading-normal focus:border-gray-500  font-semibold ${
                errors.fname ? "border-red-500" : ""
              }`}
              value={formData.fname}
              onChange={handleChange}
            />
            <label
              htmlFor="fname"
              className="absolute top-2 left-0 text-gray-500 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker text-base"
            >
              First Name
            </label>
            {errors.fname && (
              <span className="text-red-500 text-sm">{errors.fname}</span>
            )}
          </div>
          <div className="relative float-label-input w-full">
            <input
              type="text"
              id="lname"
              placeholder=" "
              className={`block w-full bg-white focus:outline-none focus:shadow-outline border border-black  py-2 px-3  appearance-none leading-normal focus:border-gray-500  font-semibold ${
                errors.lname ? "border-red-500" : ""
              }`}
              value={formData.lname}
              onChange={handleChange}
            />
            <label
              htmlFor="lname"
              className="absolute top-2 left-0 text-gray-500 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker text-base"
            >
              Last Name
            </label>
            {errors.lname && (
              <span className="text-red-500 text-sm">{errors.lname}</span>
            )}
          </div>
        </div>
        <div className="mb-6">
          <div className="relative float-label-input w-full">
            <input
              type="email"
              id="email"
              placeholder=" "
              className={`block w-full bg-white focus:outline-none focus:shadow-outline border border-black  py-2 px-3  appearance-none leading-normal focus:border-gray-500  font-semibold ${
                errors.email ? "border-red-500" : ""
              }`}
              value={formData.email}
              onChange={handleChange}
            />
            <label
              htmlFor="email"
              className="absolute top-2 left-0 text-gray-500 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker text-base"
            >
              Email
            </label>

            {errors.email && (
              <span className="text-red-500 text-sm">{errors.email}</span>
            )}
          </div>
        </div>
        <div className="mb-6">
          <div className="relative float-label-input w-full">
            <input
              type="url"
              id="url"
              placeholder=" "
              className={`block w-full bg-white focus:outline-none focus:shadow-outline border border-black  py-2 px-3  appearance-none leading-normal focus:border-gray-500  font-semibold ${
                errors.url ? "border-red-500" : ""
              }`}
              value={formData.url}
              onChange={handleChange}
            />
            <label
              htmlFor="url"
              className="absolute top-2 left-0 text-gray-500 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker text-base w-max"
            >
              Portfolio (Github or website)
            </label>

            {errors.url && (
              <span className="text-red-500 text-sm">{errors.url}</span>
            )}
          </div>
        </div>

        <div className="mb-6">
          <fieldset>
            <div
              className={`relative border border-black  ${
                errors.experience ? "border-red-500" : ""
              }`}
            >
              <select
                id="experience"
                className={`w-[100%] appearance-none text-sm  focus:outline-none  py-2 px-3 bg-transparent font-semibold select-wrapper`}
                value={formData.experience}
                onChange={handleChange}
              >
                <option value="">
                  How many years have you been involved in web3 development?
                </option>
                <option className="" value="I’m New">
                  I’m New
                </option>
                <option className="" value="1 year">
                  1 year
                </option>
                <option className="" value="1-2 years">
                  1-2 years
                </option>
                <option className="" value="2-5 years">
                  2-5 years
                </option>
                <option className="" value="5+ years">
                  5+ years
                </option>
              </select>

              <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2">
                <svg
                  className="h-4 w-4 bg-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </fieldset>
          {errors.experience && (
            <span className="text-red-500 text-sm">{errors.experience}</span>
          )}
        </div>
        <div className="py-[9rem] md:py-5 lg:py-[8rem]"></div>
        <div className="text-center">
          <button
            className="button-secoundary
      py-2
      xl:px-[92px]
      px-[30px]
      lap:px-[32px]
      lg:px-9
       before:bg-black font-bold "
          >
            <span>
              <span className="">Next</span>
            </span>
          </button>
        </div>
      </form>
    </>
  );
};

export default StapOne;

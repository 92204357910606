const index = () => {
  return (
    <footer
      className="md:fixed
      md:bottom-0
      md:left-[50%]
      md:translate-x-[-50%]
      w-full
      z-[100]
      bg-[#f3f3f3]"
    >
      <div className="mx-auto
      max-w-[1440px]
      md:px-[60px]
      lg:px-[100px]
      xl:px-[140px]">
        <div className="border-t border-black"></div>
        <div className="flex
      flex-col
      sm:flex-row
      justify-between
      gap-y-4
      pb-6
      sm:py-4">
          <div>
            <p
              className="text-base
        text-[#00000080]"
            >
              2022 Copyrights ® Blue 7 LLC
            </p>
          </div>
          <div
            className="flex-1
      sm:flex-none
      flex
      justify-between
      gap-x-10"
          >
            <a target="_" href="https://twitter.com/Blue7_io" className="text-base">
              Twitter
            </a>
            <a
              target="_"
              href="https://www.linkedin.com/company/blue7io/about/"
              className="text-base"
            >
              Linkedin
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default index;

const initialState = {
  blogs: [],
  recentBlogs: [],
  totlePaginationPaage: "",
  isLoader: false,
};

const blogReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_BLOGS":
      return {
        ...state,
        blogs: [...action.payload.data],
        totlePaginationPaage: action.payload.meta.last_page,
        isLoader: false,
      };
    case "ALL_RECENT_BLOGS":
      return {
        ...state,
        recentBlogs: [...action.payload.data],
        isLoader: false,
      };

    case "IS_LOADING":
      return {
        ...state,
        isLoader: true,
      };

    default:
      return state;
  }
};

export default blogReducers;

import { axiosClient } from "../../components/axios";

export const loading = () => {
  return {
    type: "IS_LOADING",
  };
};

export const getData = (data) => {
  return {
    type: "ALL_BLOGS",
    payload: data,
  };
};
export const getRecentData = (data) => {
  return {
    type: "ALL_RECENT_BLOGS",
    payload: data,
  };
};

export const getAllAsyncData = (data) => {
  return (dispatch) => {
    dispatch(loading());
    axiosClient
      .post("/blog-list", data)
      .then((res) => {
        dispatch(getData(res.data));
      })
      .catch((ere) => {
        console.log(ere);
      });
  };
};

export const getAllRecentAsyncData = () => {
  return (dispatch) => {
    dispatch(loading());
    axiosClient
      .post("/recent-blog-list")
      .then((res) => {
        dispatch(getRecentData(res.data));
      })
      .catch((ere) => {
        console.log(ere);
      });
  };
};

import { HashRouter, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/Home";
import Container from "./components/Container";
import FoundersCollective from "./pages/FoundersCollective";
import Portfolio from "./pages/Portfolio";
import "./App.css";
import { useEffect } from "react";
import B7xlabs from "./pages/B7xlabs";
import Blogs from "./pages/Blogs";
import BlogDetail from "./pages/BlogDetail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SingleBlogDetail from "./pages/SingleBlogDetail";
import { useParams } from "react-router-dom";

function App() {
  useEffect(() => {
    const container = document.querySelector(".scrollable-container");

    const handleWheel = (event) => {
      container.scrollLeft += event.deltaY;
      event.preventDefault();
    };

    // Add event listener for scroll wheel
    container.addEventListener("wheel", handleWheel);

    return () => {
      // Clean up the event listener when the component unmounts
      container.removeEventListener("wheel", handleWheel);
    };
  }, []);
  return (
    <HashRouter>
      <Container>
        <ToastContainer />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/public" element={<FoundersCollective />} />
          <Route path="/private" element={<Portfolio />} />
          <Route path="/b7xlabs" element={<B7xlabs />} />
          {/* <Route path="/blog" element={<Blogs />} /> */}
          {/* <Route path="/blog-detail" element={<BlogDetail />} /> */}
          <Route path="/:slug" element={<SingleBlogDetail />} />
        </Routes>
        <Footer />
      </Container>
    </HashRouter>
  );
}

export default App;

const Right = () => {
  return (
    <div
      className="w-full
      hidden
      md:flex
     items-center
     justify-center"
    >
      <div className="mb-10 lap:mb-0">
        <img src="/assets/images/hero/hero-image.png" alt="hero-image" />
      </div>
    </div>
  );
};

export default Right;

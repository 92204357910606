import { combineReducers } from "redux";
import blogReducers from "./blogReducers";
import b7xlabsReducers from "./b7xlabsReducers";

const reducers = combineReducers({
  blogData: blogReducers,
  b7xlabsdata: b7xlabsReducers,
});

export default reducers;

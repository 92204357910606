// Right.jsx

import React, { useState } from "react";
import StapOne from "./StapOne";
import StapTwo from "./StapTwo";

const Right = () => {
  const [data, setData] = useState({});
  const [step, setStep] = useState(1);

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <div className="w-full md:flex items-center justify-center pb-20">
      <div className="bg-white py-3 px-2 sm:py-6 sm:px-7 overflow-x-hidden rounded-[15px] h-full relative shadow-sm">
        <div className="prog w-full"></div>
        <div className="flex w-full space-x-8 flex-row  justify-center relative">
          {step === 1 && (
            <StapOne setData={setData} data={data} onNext={handleNextStep} />
          )}
          {step === 2 && (
            <StapTwo setData={setData} data={data} step={setStep} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Right;

const initialState = {
  status: {},
  isLoader: false,
};

const b7xlabsReducers = (state = initialState, action) => {
  switch (action.type) {
    case "IS_LOADING":
      return {
        ...state,
        isLoader: true,
      };
    case "ADD_B7XLabs":
      return {
        ...state,
        status: action.payload,
        isLoader: false,
      };

    default:
      return state;
  }
};

export default b7xlabsReducers;

import { Link } from "react-router-dom";

const index = ({ children, type, link }) => {
  return (
    <Link
      to={`/${link}`}
      className={`
      button-secoundary
      pt-[13px]

      pb-3
      ${
        children === "Private Portfolio"
          ? "xl:px-[35px] px-[22px] lap:px-[24px] lg:px-8"
          : "xl:px-[42px] px-[23.25px] lap:px-[26px] lg:px-9"
      }
    ${
      type === "primary"
        ? "before:bg-black font-bold"
        : "before:bg-[#F3F3F3] font-normal"
    }`}
    >
      <span>
        <span>{children}</span>
      </span>
    </Link>
  );
};

export default index;

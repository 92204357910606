import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { toast } from "react-toastify";
import { addB7xlabsData } from "../../Redux/actions/b7xlabsAction";

const StapTwo = ({ step, setData, data }) => {
  const [allArry, setAllArry] = useState({
    preferenceMatchingArea: [
      "Research and development",
      "Core protocol development(improvement proposals and implementation)",
      "Testnet participation",
      "Grant funding",
      "Open source collaboration",
      "Bug bounties",
      "Full time / part-time employment",
    ],
    primaryLanguage: [
      "Solidity",
      "Vyper",
      "Go (Golang)",
      "Huff Language",
      "Rust",
      "Move",
      "Cairo",
      "Haskell",
      "C++",
      "Java",
      "HTML",
      "Python",
      "Ruby",
      "JavaScript",
      "Structured Query Language",
    ],
    contractPlatform: [
      "Bitcoin",
      "Ethereum",
      "Solana",
      "Cosmos",
      "Polkadot",
      "Aptos",
      "Sui",
      "Fuel",
      "Near",
    ],
  });

  const [formState, setFormState] = useState({
    preferenceArea: [],
    primaryLanguage: [],
    contractPlatform: [],
    willingnessToOnboard: null,
  });
  const dispatch = useDispatch();
  const b7xlabs = useSelector((state) => state.b7xlabsdata.status);

  const [otherLanguage, setOtherLanguage] = useState("");

  const [otherContractPlatform, setOtherContractPlatform] = useState("");

  const [errors, setErrors] = useState({});

  const handleCheckboxChange = (category, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [category]: prevState[category].includes(value)
        ? prevState[category].filter((e) => e !== value)
        : [...prevState[category], value],
    }));
  };

  const handleRadioChange = (value) => {
    setFormState((prevState) => ({
      ...prevState,
      willingnessToOnboard: value,
    }));
  };

  const navigate = useNavigate();

  const handleAddPrimaryLanguage = () => {
    const newErrors = {};

    if (otherLanguage.trim() !== "") {
      setAllArry((prevState) => ({
        ...prevState,
        primaryLanguage: [...prevState.primaryLanguage, otherLanguage],
      }));
      if (formState.primaryLanguage.includes("Other")) {
        handleCheckboxChange("primaryLanguage", "Other");
      }

      setOtherLanguage("");
    } else {
      newErrors.otherLanguage = "Enter Other Language.";
    }

    if (otherContractPlatform.trim() !== "") {
      setAllArry((prevState) => ({
        ...prevState,
        contractPlatform: [
          ...prevState.contractPlatform,
          otherContractPlatform,
        ],
      }));
      if (formState.contractPlatform.includes("Other")) {
        handleCheckboxChange("contractPlatform", "Other");
      }

      setOtherContractPlatform("");
    } else {
      newErrors.otherContractPlatform = "Enter Other Contract Platform.";
    }

    setErrors(newErrors);

    // ... rest of the function
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userCaptcha = document.getElementById("captcha_input").value;

    const newErrors = {};

    if (formState.preferenceArea.length === 0) {
      newErrors.preferenceArea = "Select at least one preference area.";
    }

    if (formState.primaryLanguage.length === 0) {
      newErrors.primaryLanguage = "Select at least one programming language.";
    }

    if (formState.contractPlatform.length === 0) {
      newErrors.contractPlatform =
        "Select at  Familiarity With Smart Contract Platforms.";
    }
    if (!formState.willingnessToOnboard) {
      newErrors.willingnessToOnboard =
        "Select your willingness to onboard to new ecosystem / language / frameworks.";
    }

    if (!userCaptcha.trim()) {
      newErrors.captcha = "Enter the Captcha.";
    } else if (!validateCaptcha(userCaptcha)) {
      newErrors.captcha = "Captcha does not match.";
      document.getElementById("captcha_input").value = "";
    }

    if (Object.keys(newErrors).length === 0) {
      setFormState({
        preferenceArea: [],
        primaryLanguage: [],
        contractPlatform: [],
        willingnessToOnboard: null,
      });
      if (validateCaptcha(userCaptcha)) {
        loadCaptchaEnginge(5, "black", "white");
        document.getElementById("captcha_input").value = "";

        const newPrimaryLanguage = formState.primaryLanguage.filter(
          (val) => val !== "Other"
        );
        const newContractPlatform = formState.contractPlatform.filter(
          (val) => val !== "Other"
        );

        setData({
          ...data,
          ...formState,
          primaryLanguage: newPrimaryLanguage,
          contractPlatform: newContractPlatform,
        });
        setErrors({});

        console.log(data, "this is a main data");

        const mainformData = new FormData();

        // Append the fields to the FormData object
        mainformData.append("first_name", data.fname);
        mainformData.append("last_name", data.lname);
        mainformData.append("email", data.email);
        mainformData.append("portfolio", data.url);
        mainformData.append("experience", data.experience);
        mainformData.append("preference", data.preferenceArea);
        mainformData.append("programming_languages", data.primaryLanguage);
        mainformData.append("contract_platforms", data.contractPlatform);
        mainformData.append("willingness", data.willingnessToOnboard);

        dispatch(addB7xlabsData(mainformData)).then((ref) => {
          if (ref?.data?.code === 200) {
            toast.success("Form submitted successfully", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            step(1);
          } else {
            toast.error("Something Went wrong", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
      } else {
        document.getElementById("captcha_input").value = "";
      }
    } else {
      toast.error("Please fill in all required fields", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setErrors(newErrors);
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(5, "black", "white");
  }, []);

  return (
    <>
      <form className="stapTwo" onSubmit={handleSubmit}>
        <div className="mb-2 text-black font-bold capitalize text-lg">
          other information
        </div>

        <div className="my-2">
          <div className="mb-2 text-black font-semibold capitalize text-base">
            1. Areas of preference for matching
          </div>
          {allArry.preferenceMatchingArea.map((res, i) => (
            <div
              className="flex gap-2 ms-4 py-[2px] checkbox-container"
              key={i}
            >
              <input
                id={res}
                type="checkbox"
                checked={formState.preferenceArea.includes(res)}
                className="accent-black"
                onChange={() => handleCheckboxChange("preferenceArea", res)}
              />
              <label
                htmlFor={res}
                className="select-none text-[13px] text-black font-medium"
              >
                {res}
              </label>
            </div>
          ))}
          {errors.preferenceArea && (
            <span className="text-red-500">{errors.preferenceArea}</span>
          )}
        </div>

        <div className="my-2">
          <div className="mb-2 text-black font-semibold capitalize text-base">
            2. Primary programming languages
          </div>

          <div className="flex flex-wrap justify-start items-start ms-4">
            {allArry.primaryLanguage.map((res, i) => (
              <div
                className="flex gap-2 items-start  py-[2px]  w-full es:w-[50%] sm:w-[33%] md:w-[50%] xl:w-[33%]"
                key={i}
              >
                <input
                  id={res}
                  type="checkbox"
                  checked={formState.primaryLanguage.includes(res)}
                  className="accent-black mt-[3px]"
                  onChange={() => handleCheckboxChange("primaryLanguage", res)}
                />
                <label
                  htmlFor={res}
                  className="select-none text-[13px] text-black font-medium"
                >
                  {res}
                </label>
              </div>
            ))}
          </div>
          <div className="flex flex-wrap justify-between items-start ms-4">
            <div className="flex gap-2 items-center py-[2px] w-full ">
              <input
                id="otherLanguage"
                type="checkbox"
                checked={formState.primaryLanguage.includes("Other")}
                className="accent-black mt-[3px]"
                onChange={() =>
                  handleCheckboxChange("primaryLanguage", "Other")
                }
              />
              <label
                htmlFor="otherLanguage"
                className="select-none text-[13px] text-black font-medium"
              >
                Other
              </label>
              {formState.primaryLanguage.includes("Other") && (
                <div className="relative">
                  <input
                    className="border border-black outline-none placeholder:text-sm placeholder:text-black placeholder:ps-1 ps-1"
                    type="text"
                    value={otherLanguage}
                    onChange={(e) => setOtherLanguage(e.target.value)}
                    placeholder="Other Language"
                  />
                  <div className="absolute top-0 right-0  ">
                    <button
                      type="button"
                      onClick={handleAddPrimaryLanguage}
                      className="button-secoundary p-[1px_4px] text-sm before:bg-black font-semibold"
                    >
                      <span>
                        <span className="text-sm">Add</span>
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {errors.primaryLanguage && (
            <span className="text-red-500">{errors.primaryLanguage}</span>
          )}
        </div>

        <div className="my-2">
          <div className="mb-2 text-black font-semibold capitalize text-base">
            3. Familiarity with smart contract platforms
          </div>

          <div className="flex flex-wrap justify-between items-start ms-4">
            {allArry.contractPlatform.map((res, i) => (
              <div
                className="flex gap-2 items-start  py-[2px]  w-full es:w-[50%] sm:w-[33%] md:w-[50%] xl:w-[33%]"
                key={i}
              >
                <input
                  id={res}
                  type="checkbox"
                  className="accent-black mt-[3px]"
                  checked={formState.contractPlatform.includes(res)}
                  onChange={() => handleCheckboxChange("contractPlatform", res)}
                />
                <label
                  htmlFor={res}
                  className="select-none text-[13px] text-black font-medium"
                >
                  {res}
                </label>
              </div>
            ))}
          </div>
          <div className="flex flex-wrap justify-between items-start ms-4">
            <div className="flex gap-2 items-center py-[2px] w-full ">
              <input
                id="otherContractPlatform"
                type="checkbox"
                checked={formState.contractPlatform.includes("Other")}
                className="accent-black mt-[3px]"
                onChange={() =>
                  handleCheckboxChange("contractPlatform", "Other")
                }
              />
              <label
                htmlFor="otherContractPlatform"
                className="select-none text-[13px] text-black font-medium"
              >
                Other
              </label>
              {formState.contractPlatform.includes("Other") && (
                <div className="relative">
                  <input
                    className="border border-black outline-none placeholder:text-sm placeholder:text-black placeholder:ps-1 ps-1"
                    type="text"
                    value={otherContractPlatform}
                    onChange={(e) => setOtherContractPlatform(e.target.value)}
                    placeholder="Other Language"
                  />
                  <div className="absolute top-0 right-0  ">
                    <button
                      type="button"
                      onClick={handleAddPrimaryLanguage}
                      className="button-secoundary p-[1px_4px] text-sm before:bg-black font-semibold"
                    >
                      <span>
                        <span className="text-sm">Add</span>
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {errors.contractPlatform && (
            <span className="text-red-500">{errors.contractPlatform}</span>
          )}
        </div>

        <div className="my-2">
          <div className="mb-2 text-black font-semibold capitalize text-base">
            4. Willingness to onboard to new ecosystem / language / frameworks
          </div>

          <div className="flex flex-wrap gap-4 ms-4">
            <div className="flex gap-2">
              <input
                id="yes"
                type="radio"
                className="accent-black"
                checked={formState.willingnessToOnboard === "yes"}
                onChange={() => handleRadioChange("yes")}
              />
              <label
                htmlFor="yes"
                className="select-none text-[13px] text-black font-medium"
              >
                yes
              </label>
              <input
                id="no"
                type="radio"
                className="accent-black"
                checked={formState.willingnessToOnboard === "no"}
                onChange={() => handleRadioChange("no")}
              />
              <label
                htmlFor="no"
                className="select-none text-[13px] text-black font-medium"
              >
                No
              </label>
            </div>
            {errors.willingnessToOnboard && (
              <span className="text-red-500">
                {errors.willingnessToOnboard}
              </span>
            )}
          </div>
        </div>

        <div className="my-2">
          <div className="mb-2 text-black font-semibold capitalize text-base">
            5. Captcha Verification
          </div>
          <div className=" py-[2px] w-full   ms-4">
            <div>
              <LoadCanvasTemplate
                reloadColor="black"
                reloadText="Reload Captcha"
              />
            </div>
            <div>
              <input
                id="captcha_input"
                type="text"
                className={`block w-full bg-white focus:outline-none focus:shadow-outline border border-black  py-2 px-3 my-3 leading-normal focus:border-gray-500  font-semibold   ${
                  errors.captcha ? "border-red-500" : ""
                }`}
                placeholder="Enter Captcha"
              />
              {errors.captcha && (
                <span className="text-red-500">{errors.captcha}</span>
              )}
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            className="button-secoundary
      py-2
      xl:px-[92px]
      px-[30px]
      lap:px-[32px]
      lg:px-9
       before:bg-black font-bold "
          >
            <span>
              <span className="">Submit</span>
            </span>
          </button>
        </div>
      </form>
    </>
  );
};

export default StapTwo;
